import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { accountReducer, initialState } from './accountReducer';
import AdminTopNav from '../Utility/TopNav';
import AdminSideBar from '../Utility/SideNav';
import Switch from 'react-switch';

// import { useWebProperties } from '../../Common/webProperties';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import { getAccountPermissions } from './Services/getAccountPermissions';
import API from '../../Common/Network/API';


export default function AccountPermissions() {
  const { params: { accountId } } = useRouteMatch();
  // const getUser = useSelector(state => state.auth)
  const [state, dispatch] = useReducer(accountReducer, initialState)
  // const { ACCOUNTS } = useWebProperties();

  useEffect(() => {
    getAccountPermissions(dispatch, accountId);
    // eslint-disable-next-line
  }, [accountId])

  // Squads list can be enable or disable on user convience
  const isProjectsEnable = async (feature_id, account_id, permission_status) => {
    try {
      var response = await API.post("accounts.php", {
        feature_id: feature_id,
        account_id: account_id,
        action: "update_account_permissions",
        permission_status: permission_status === '1' ? '0' : '1',
      }, {}, false);
      if (response.status === 'True') {
        getAccountPermissions(dispatch, accountId);
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }

  return (
    <div className="container-scroller">
      <AdminTopNav />
      <div className="container-fluid page-body-wrapper">
        <AdminSideBar />
        <div className="main-panel">
          <div className="mt-2">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="justify-content-between row">
                    <h4 className="card-title ml-2">BRONZE</h4>
                  </div>
                  {state.accountFeatures.length > 0 && state.accountFeatures.map((list, index) => {
                    return (
                      <li className="nav-item" style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        marginRight: '1rem',
                        alignItems: 'flex-end',
                      }}>
                        <div
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#007bff",
                          }}
                          className="nav-link">
                          <div style={{
                            fontWeight: "600",
                            fontSize: "16px",
                          }}>
                            <img alt='' style={{ width: '23px', height: '23px', backgroundColor: list.color }} />
                            <span className="menu-title pl-3" style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '8rem',
                              lineHeight: '1.5',
                            }}>{list.name}</span>
                          </div>
                        </div>
                        {/* <div title='Hide or Un Hide Project'>
                          <Switch
                            checked={(list.permission_status === "1") ? true : false}
                            onChange={() => isProjectsEnable(list.id, list.account_id, list.permission_status)}
                            className="react-switch"
                          />
                        </div> */}
                          <Switch
                            checked={(list.permission_status === "1") ? true : false}
                            onChange={() => isProjectsEnable(list.id, list.account_id, list.permission_status)}
                            handleDiameter={28}
                            offColor="#CD5C5C"
                            onColor="#008000"
                            offHandleColor="#fff"
                            onHandleColor="#fff"
                            height={30}
                            width={100}
                            borderRadius={6}
                            activeBoxShadow="0px 0px 1px 2px #fffc35"
                            uncheckedIcon={
                              <div style={{ paddingTop: 5, marginLeft: -21, width: 61 }}>
                                <b style={{ color: 'white' }} >Disabled</b>
                              </div>
                            }
                            checkedIcon={
                              <div style={{ paddingTop: 5, marginLeft: 10, width: 60 }}>
                                <b style={{ color: 'white' }}>Enabled</b>
                              </div>
                            }
                            className="react-switch"
                            id="small-radius-switch"
                          />
                      </li>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}