/*
filename:modifyMainTask.js
purpose:To modify user story
Developers: G Naveen Kumar[G.N.K]
 */
import React, { useEffect, useReducer,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
//import { getUsers, getProjects, getAgileProjects, addTodoToProject } from './network';
import { getUsers } from './Services/getUsers';
import { getProjects } from './Services/getProjects';
import { getAgileProjects } from './Services/getAgileProjects';
import { createTodoToProject } from './Services/createTodoToProject';
import { privateToDoReducer, initialState } from './privateToDoReducer';
import Select from 'react-select';
import RootLoader from '../Loader/RootLoader';
import { useWebProperties } from '../webProperties';
import { addMainTask } from './Services/addMainTask';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import getAiTaskDetails from '../TasksModals/Services/getAiTaskDetails';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function AddToToDoProjects(props) {
    const [state, dispatch] = useReducer(privateToDoReducer, initialState);
    const getUser = useSelector(state => state.auth);

    const [manualValue, setManualValue] = useState(null);
    // added
    // const [selectedStoryValue, setSelectedStoryValue] = useState({ value: '2', label: '2 hrs' });
    // const [selectedPriorityValue, setSelectedPriorityValue] = useState({ value: '2', label: '2' });
    // const [selectedUserValue, setSelectedUserValue] = useState([]);
    // const [selectedEpicValue, setSelectedEpicValue] = useState([])


    const classNames = useStyles();
    const  {PROJECT,MAINTASK_DESCRIPTION,STORY_POINTS,SELECT_SQUAD_MEMBER,ACCEPTANCE_CRITERIA, NEWMAINTASK, MAINTASK_ADD,
        DEFINITION_OF_DONE,PRIORITY_LEVEL, MAINTASKNAME, AGILE_PROJECT_NAME, ADD_TO_TODO_PROJECTS } = useWebProperties();
    useEffect(() => {
        getAgileProjects(dispatch,getUser.user.empId); // GNK --> 01
        // getUsers(dispatch, getUser.user);
        getProjects(dispatch, getUser.user);

        dispatch(actions.setModifyMainTask(props.data.data.taskId, props.data.data.story_title, props.data.data.story_desc, "",
            "", props.data.data.acceptance_criteria, props.data.data.story_points, props.data.data.assigned_to, props.data.data.priority_level,
            props.data.data.targetDate,props.data.data.device_id))
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        getUsers(dispatch, getUser.user,state.agileProjectSelected);
        getProjects(dispatch, getUser.user,state.agileProjectSelected);
        // eslint-disable-next-line
    }, [state.agileProjectSelected])

    const [isLoading,setIsLoading] = useState(false);
    var userDetails = [];
    //Initalizing empty array to storyPoints and  priorityLevels[G.N.K]
    var storyPoints = [];
    var priorityLevels = [];
    var epicsList = [];
    var agileProjectsList = [];
    //Initalizing values to storyPointsArray and  priorityLevelsArray[S.S]
    var storyPointsArray = [{ id: '1', story: '1' }, { id: '2', story: '2' }, { id: '3', story: '3' }, { id: '5', story: '5' }, { id: '8', story: '8' }, { id: '13', story: '13' }]
    var priorityLevelsArray = [{ id: '1', priority: '1' }, { id: '2', priority: '2' }, { id: '3', priority: '3' }, { id: '4', priority: '4' }]
    //pushing values to the  storyPoints and priorityLevels[S.S]
    storyPointsArray.map((storyPointsArray) => {
        return (
            storyPoints.push({ 'value': storyPointsArray.id, 'label': storyPointsArray.story })
        );
    })
    priorityLevelsArray.map((priorityLevelsArray) => {
        return (
            priorityLevels.push({ 'value': priorityLevelsArray.id, 'label': priorityLevelsArray.priority })
        );
    })

    state.users.map((users) => {
        return (
            users.workingStatus === "Active" ?
            userDetails.push({ 'value': users.id, 'label': users.name,'device_id':users.device_id }) : null
        );
    })
    state.projects.map((epics) => {
        return (
            epicsList.push({
                'value': epics.idea_id, 'label': epics.idea_title
            })
        );
    })
    state.agileProjects.map((agileProjects) => {
        return (
            agileProjectsList.push({
                'value': agileProjects.id, 'label': agileProjects.value
            })
        );
    })
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{props.data.view === "addUserStory" ? NEWMAINTASK : 'Assign Personal Task to Project'}</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                  {/* old form is updated */}
                                 <div className="modal-body overflow-auto" style={{ height: 500 }}>

                                 <div class="input-group mb-3" style={{position:"relative"}}>
                                    <input type="text" class="form-control" style={{borderRadius:'25px',padding:'8px'}} placeholder="Type to generate using AI" aria-label="Recipient's username" aria-describedby="button-addon2"
                                        value={state.aitaskinput.value}
                                        onChange={(event)=>dispatch(actions.aitaskinput(event.target.value))}
                                        />
                                    {/* <span style={{ color: "red", fontSize: '12px' }}>{state.aitaskinput.errorStatus ? (state.aitaskinput.errormessage   ).toLowerCase() : ""}</span> */}
                                    <button type="button" class="btn btn-outline-secondary btn-sm" 
                                    style={{borderRadius:'10000px',position:"absolute",right:"5px",padding:"3px",marginTop:'6px',marginRight:'3px',zIndex:'500'}}
                                    onClick={()=>getAiTaskDetails(state.aitaskinput.value,dispatch,setIsLoading)}
                                    disabled={state.aitaskinput.value.length===0 || isLoading}
                                    >
                                    {!isLoading ? <ArrowUpwardRoundedIcon fontSize={"medium"} backgroundColor={"grey"}/> :
                                     (<div class="spinner-border text-primary" role="status">
                                     <span class="visually-hidden"></span>
                                 </div>)
                                 }
                                    
                                    </button>
                        
                                </div>

                                    <div class="form-group" style={{ height: 'auto' }}>
                                        <label for="recipient-name" class="col-form-label pt-0" style={{ width: '150px' }}>{MAINTASKNAME}<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.taskTitle.value}
                                            onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskTitle.errorStatus ? state.taskTitle.errormessage : ""}</span>
                                    </div>

                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="recipient-name" class="col-form-label pt-2" style={{ width: '100px' }}>{MAINTASK_DESCRIPTION}<span style={{ color: "red" }} >*</span></label>

                                        <textarea class="form-control" id="description" name="description" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '1px solid blackpx', borderLeft: '1px solid black', borderRight: '1px solid black', height: '70px', }}
                                            value={state.taskDescription.value}
                                            placeholder="As a [person],____ I [want to],____ [so that]____"
                                            onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                        <span style={{ color: "gray", fontSize: '12px' }}>{state.taskDescription.errorStatus ? state.taskDescription.errormessage : ""}</span>
                                    </div>

{/* added */}
<div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
<label for="story" className="col-form-label pt-2" style={{ width: '150px' }}>{AGILE_PROJECT_NAME}<span style={{ color: "red" }} >*</span></label>
                                                {/* Selecting Agile Project */}
                                                <Select
                                                    className="form-control"
                                                    placeholder="Select Project"
                                                    value={agileProjectsList.value}
                                                    maxMenuHeight={130}
                                                    onChange={(selectedOption) => {
                                                        // dispatching selected value to the reducer
                                                        dispatch(actions.agileProjectSelected(selectedOption.value))
                                                    }}
                                                    options={agileProjectsList}
                                                />

</div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '70px' }}
                                            value={state.acceptanceCriteria.value}
                                            onChange={(event) => dispatch(actions.acceptanceCriteria(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.acceptanceCriteria.errorStatus ? state.acceptanceCriteria.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px", display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                            <label for="epic" className="col-form-label pt-2" style={{ width: '100px' }}>{PROJECT} Name</label>

                                            <Select
                                                className="form-control "
                                                placeholder={`Select ${PROJECT}`}
                                                value={epicsList.value}
                                                maxMenuHeight={130}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.epicSelected(selectedOption.value))
                                                }}
                                                options={epicsList}
                                            />
                                        </div>
                                        <div>
                                            <label for="user" className="col-form-label pt-2" style={{ width: '180px' }}>{SELECT_SQUAD_MEMBER}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={` ${SELECT_SQUAD_MEMBER}`}
                                                maxMenuHeight={130}
                                                value={userDetails.value}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.userSelected(selectedOption.value))
                                                    dispatch(actions.userDeviceSelected(selectedOption.device_id))
                                                }}
                                                options={userDetails}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px", display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                            <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{STORY_POINTS}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={`Select ${STORY_POINTS}`}
                                                value={storyPoints.value}
                                                maxMenuHeight={110}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption.value === 'Other') {
                                                        setManualValue("Other");
                                                    } else {
                                                        setManualValue(null);
                                                        dispatch(actions.storySelected(selectedOption.value))
                                                    }
                                                }}
                                                options={storyPoints}
                                            />
                                        </div>
                                        <div>
                                            {/* drop down  for priorityLevel[S.S]  */}
                                            <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{PRIORITY_LEVEL}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={`Select ${PRIORITY_LEVEL}`}
                                                value={priorityLevels.value}
                                                maxMenuHeight={110}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.prioritySelected(selectedOption.value))
                                                }}
                                                options={priorityLevels}
                                            />
                                        </div>
                                        {manualValue === 'Other' && (
                                            <>
                                                <input
                                                    className="form-control "
                                                    type="number"
                                                    onChange={(e) => dispatch(actions.storySelected(e.target.value))}
                                                    placeholder={`Enter ${STORY_POINTS} manually`}
                                                />
                                                <br />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                {state.isLoading ? <RootLoader/> : 
                                <div>
                                    { props.data.view === "addUserStory" ? <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} 
                                onClick={(event) => {addMainTask(state, dispatch, getUser.user,props.handleClose, props.data.data.pt_id, props.data.data.story_title, ADD_TO_TODO_PROJECTS, props.data.view, MAINTASK_ADD)
                                        }}
                                >Add</button> 
                                : <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} 
                                onClick={(event) => { createTodoToProject(state, dispatch, getUser.user,props.handleClose, props.data.data.pt_id, props.data.data.story_title, ADD_TO_TODO_PROJECTS, props.data.view, MAINTASK_ADD)}} 
                                >Add</button>}&nbsp;&nbsp;
                                </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}