import React, { useEffect, useReducer, useState } from 'react'
import { getArchiveAllTasks } from '../ConsolidatedToDo/Services/getConsolidatedToDo';
import { consolidatedToDoReducer } from '../ConsolidatedToDo/reducer';
import { initialState } from './tasksReducer';
import { useSelector } from 'react-redux';
import RootLoader from '../../Common/Loader/RootLoader';
import { getAllUserStoriesMessages } from './Services/getAllUserStoriesMessages';
import { isMobile } from 'react-device-detect';
import { getSubStringId } from '../../Common/SubStringConvert';
import ChatBox from '../../Common/ChatMainTask';
import MainTaskVerify from '../../Common/TaskVerify/mainTaskVerify';
import MainTaskInfo from '../../Common/TasksModals/mainTaskInfo';
import ModifyMainTask from '../../Common/TasksModals/modifyMainTask';
import TaskInfo from '../UserDashboard/taskInfo';
import { useWebProperties } from '../../Common/webProperties';


export default function ArchiveAllTasks ({searchWord}) {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(consolidatedToDoReducer,initialState)
    const [chatOpen, setChatOpen]=useState(false)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [time, setTime]=useState(true)
    const { CHAT, COMMENTS, VERIFY, SCRUM_MASTER, ACTION_ICON, PRODUCT_OWNER, role_array,REASSIGN ,VIEW_DETAILS} = useWebProperties();

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
                setTime(false)
            }, delay);
        };
    };

    // Define the debounced search function
    const debouncedSearch = debounce((searchWord) => {
        if (searchWord.length > 0) {
            getArchiveAllTasks(dispatch, getUser.user, searchWord);
        }
    }, 1000); // 1000 milliseconds debounce time

    // Call the debounced search function when handling search

    useEffect(()=> {
        if (searchWord.length > 0) {
            setTime(true)
            debouncedSearch(searchWord);}}, [searchWord])

    useEffect(()=> {
        if (searchWord.length > 0) {
            getAllUserStoriesMessages(dispatch, getUser.user) }}, []);

    const handleOpen = (action, index, sno) => {
            var info
            setOpen({ status: true, index: index, action: action });
            if (action === "unassigned_taskInfo") {
                var view_status1 = "taskInfo"
                info = {
                    main_task_id: index.main_task_id,
                    view: view_status1,
                    us_id: index.us_id,
                    projectName: index.epic_name, //epic/module name
                    epicId: index.epic_id,
                    moduleName: index.sprint_desc,
                    moduleId: index.sprint_id,
                    title: index.story_title,
                    description: index.story_desc,
                    taskId: index.story_id,
                    createdDate: index.created_date,
                    taskProgress: index.story_status,
                    storyPoints: index.story_points,
                    acceptanceCriteria: index.acceptance_criteria,
                    assignedTo: index.assignedto,
                    assignedBy: index.assignedby,
                    completedDate: index.modified_date,
                    completedStatus: index.complete_status,
                    targetDate: index.target_date,
                    device_id: index.device_id,
                    project_name: index.project_name,
                }
                
            }
            else if (action === "commentInfo") {
                info = {
                    us_id: index.us_id,
                    title: index.story_title,
                    id: index.story_id,
                }
            }
            else if (action === "taskInfo" || action === "commentInfo") {
                var view_status = action;
                info = {
                    view: view_status,
                    projectName: index.project_name,
                    moduleName: index.sprint_desc,
                    title: index.story_title,
                    description: index.story_desc,
                    taskId: index.story_id,
                    targetDate: index.target_date,
                    timeLeft: index.timeLeft,
                    extraHours: index.extraHours,
                    status: index.status,
                    createdDate: index.created_date,
                    taskProgress: index.task_status,
                    storyPoints: index.story_points,
                    acceptanceCriteria: index.acceptance_criteria,
                    assignedTo: index.assignedto,
                    assignedBy: index.assignedby,
                    completedDate: index.modified_date,
                    completedStatus: index.complete_status,
                    us_id: index.us_id
                };
            }
            else if (action === "Verify") {
                info = {
                    us_id: index.us_id,
                    mainTaskId: index.id,
                    title: index.story_title,
                    description: index.story_desc,
                    action: action,
                    moduleId: index.sprint_desc,
                }
            }
            else if (action === "reassign") {
                info = {
                    us_id: index.us_id,
                    projectName: index.epic_name,
                    moduleName: index.sprint_desc,
                    moduleId: index.story_desc,
                    ideano: index.epic_id,
                    id:index.assignedTo,
                    targetDate:index.target_date,
                    title: index.story_title,
                    description: index.story_desc,
                    taskId: index.story_id,
                    acceptanceCriteria: index.acceptance_criteria,
                    storyPoints: index.story_points,
                    // userSelected: index.modifiedby,
                // userSelected:index.assignbyId,
                    // assignbyId:index.modifiedby,
                    userDetails:index.assignedto,
                //    assignBy:index.assignby,
                //  assignedTo: index.assignto,
                assignedTo: index.assignTo,
                assignedBy: index.assignby,
                    priorityLevel: index.priority_level,
                    sprintTargetDate: index.sprint_target_date,
                    action: action,
                    targetDate: index.targettime,
                    currenttime: index.currenttime,
                    // currentDate: index.currentDate,
                    activeStatus: index.activeStatus,
                    backlogs: index.backlogs,
                    device_id: index.device_id,
                    player_id: index.player_id,
                    status: index.complete_status,
                }   
            }
            else {
                info = { action: action, id: state.currentTasks[index].taskid, sno: sno }
            }
            setCardInfo(info);
        };

    const handleClose = () => {

        setOpen({ status: false, index: 0 });
        getArchiveAllTasks(dispatch, getUser.user, searchWord);
        getAllUserStoriesMessages(dispatch, getUser.user);
        };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }

    const handleOpenChat = (action, id, sno) => {
        setChatOpen(true);
        // updateChat(sno,dispatch,getUser.user)
        var info = { action: action, id: id, sno: sno };
        setCardInfo(info);
    };

    const handleChatClose = () => {
        setChatOpen(false);
        getAllUserStoriesMessages(dispatch, getUser.user);
    };
            
    const getMessagesCount = (id, msg, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }}
                                onClick={(event) => handleOpenChat("maintask", id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length>9?"+9":msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }}
                                onClick={(event) => handleOpenChat("maintask", id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

   
    return (
    <div>
        {(state.isLoading||time) ? <RootLoader /> :
            <div className='arc'>
                                  
            {state.allTasks?.length > 0 ? 
                state.allTasks?.map((tasks, index) => {
                    return (
                        <div className="col-12" key={tasks.taskid}>
                            <div className="card col-12">
                                <div className="container-fluid col-12 row" >
                                    <div class="d-flex col-12" style={{ padding: 0 }}>
                                        <div class="d-flex pt-2 " style={{ width: '860px' }}>
                                            <b style={{ cursor: 'pointer', paddingTop: 10, fontSize: '12px' }} onClick={(event) => handleOpen("unassigned_taskInfo", tasks)}><del> {getSubStringId(getUser.user.corp, 3)}{'-'}{tasks.us_id}{'-'}{tasks.story_title}</del></b>
                                            <div>{tasks.completeStatus === 'verified' ? <p style={{ backgroundColor: 'green', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: isMobile?'':'100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" title={'Verified by ' + tasks.modifiedby}>Verified</p> : null}</div>

                                        </div>
                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '40px', padding: "0", display: isMobile? "none" : {} }} >
                                            {
                                                getMessagesCount(tasks.us_id, state.allMessages, getUser.user.empId)
                                            }
                                        </button>
                                        <button style={{
                                            border: "0",
                                            width: "8px",
                                            height: "30px",
                                            backgroundColor: "transparent",
                                            marginLeft: "20px",
                                            display: isMobile? "none" : {},
                                        }}
                                            type="button"
                                            onClick={() =>
                                                handleOpen(
                                                    "commentInfo", tasks
                                                )
                                            }
                                        >
                                            <img
                                                src="images/common/comments.svg"
                                                title={COMMENTS}
                                                alt="logo"
                                                style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                                            />
                                        </button>
                                        <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, padding: '5px' }}>
                                        
                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0', display: isMobile? "none" : {} }} />
                                            </a>
                                            
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                {/* <div>{(accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify", tasks)}>{VERIFY}</button> : null}
                                                </div>         
                                                                                                                                */}
                                       <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>

                                                {/* Reassign */}
                                                {(tasks.complete_status === 'completed' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign",state.allTasks[index])}>{REASSIGN}</button> : null}  
                                                 {(tasks.complete_status === 'completed' && tasks.complete_status !== 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify",tasks)}>{VERIFY}</button> : null}

                                            </div>
                                        </div>
                                        {
                                            isMobile &&
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                      {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button> */}
                                      {/* {(tasks.completeStatus === 'completed' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign",state.allTasks[index])}>{REASSIGN}123</button> : null}  
                                                 {(tasks.completeStatus === 'completed' && tasks.completeStatus !== 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify",tasks)}>{VERIFY}</button> : null} */}


                                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '30px', padding: "0", marginBottom:'auto', marginTop:'5px' }} >
                                            {
                                                getMessagesCount(tasks.us_id, state.allMessages, getUser.user.empId)
                                            }
                                        </button>
                                        <button style={{
                                            border: "0",
                                            width: "8px",
                                            height: "30px",
                                            backgroundColor: "transparent",
                                            marginLeft: "5px",
                                        }}
                                            type="button"
                                            onClick={() =>
                                                handleOpen(
                                                    "commentInfo", tasks
                                                )
                                            }
                                        >
                                            <img
                                                src="images/common/comments.svg"
                                                title={COMMENTS}
                                                alt="logo"
                                                style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                                            />
                                        </button>
                                        <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, padding: '5px' }}>
                                       
                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>

                                                 {(tasks.complete_status === 'completed' && tasks.complete_status !== 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify",tasks)}>{VERIFY}</button> : null}                                                                        </div>
                                            {(tasks.complete_status === 'completed' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign",state.allTasks[index])}>{REASSIGN}</button> : null}  
                                            
                                            </div>
                                        </div>
                                            
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>


                    )
                }
                ) : searchWord&& <p style={{fontSize:"20px",textAlign:"center",marginTop:"100px"}}>No Data Found</p>}
            {chatOpen ? (
                <ChatBox
                    open={chatOpen}
                    handleClose={handleChatClose}
                    data={cardInfo}
                    handleModalClose={handleChatClose}
                />
            ) : null}
            {
                open.action === "unassigned_taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
              {
                open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
            {
                (open.action === "Verify") ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
                 {
                (open.action === "reassign") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
              {/* {
                (open.action === "modify" || open.action === "reassign") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            } */}


            {open.action === "commentInfo" ? (
                <TaskInfo
                    open={open.status}
                    handleClose={handleClose}
                    data={cardInfo}
                    handleModalClose={handleModalClose}
                />
            ) : null}
        </div>
        }
    </div>
    )
};
